import React, { useEffect } from 'react';
import { useMixpanel } from 'gatsby-plugin-mixpanel';

import PageLayout from '../layouts/Page';
import Button from '../components/Button';

import './404.scss';

function NotFoundPage() {
  const mixpanel = useMixpanel();

  useEffect(() => {
    mixpanel.register({ origin: 'Website - Landing' });
    mixpanel.track('404 Page Viewed');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Page404">
      <PageLayout>
        <div className="Page404Content">
          <h1>Page not found</h1>
          <div>Oops! The page you are looking for has been removed or relocated.</div>
          <Button solid color="green" to="/">Go Back</Button>
        </div>
      </PageLayout>
    </div>
  );
}

export default NotFoundPage;
