import React from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import ArrowAlternate from '../assets/icons/arrow-alternate.svg';

import './Button.scss';

const ButtonLogo = (props) => {
  const { children } = props;
  return <div className="ButtonLogo">{children}</div>;
};

const Button = ({
  children,
  role,
  to,
  rel,
  target,
  external = false,
  className,
  onClick,
  size,
  arrow,
  style = {},
}) => {
  let isExternal = false;
  if (external) isExternal = true;
  if (to?.startsWith('http') || to?.startsWith('/docs')) isExternal = true;

  if (role === 'button') {
    return (
      <button
        onClick={onClick}
        className={`Button ${className || ''} layout ${size || ''}`}
        style={style}
        rel={rel}
      >
        {children}
      </button>
    );
  } else if (!isExternal) {
    return (
      <a
        onClick={onClick}
        onKeyDown={(event) => {
          if (event.keycode === 13) {
            onClick();
          }
        }}
        className={`Button ${className || ''} layout ${size || ''}`}
        href={to}
        style={style}
      >
        {children}
        {arrow && <ArrowAlternate />}
      </a>
    );
  } else {
    return (
      <OutboundLink
        onClick={onClick}
        onKeyDown={(event) => {
          if (event.keycode === 13) {
            onClick();
          }
        }}
        className={`Button ${className || ''} ${size || ''}`}
        href={to}
        style={style}
        target={target}
      >
        {children}
        {arrow && <ArrowAlternate />}
      </OutboundLink>
    );
  }
};

export default Object.assign(Button, {
  Logo: ButtonLogo,
});
